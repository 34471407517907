.container {
	display: flex;
	width: 100%;
	max-height: 80vh;
}

.sidebar {
	width: 22%;
	background-color: #f8fafc;
	border-right: 1px solid #e0e0e0;
	border-right: 1px solid #e0e0e0;
	flex-direction: column;
}

.logo {
	display: flex;
	font-size: 24px;
	padding-top: 22px;
	align-items: center;
	margin-left: 10px;
	padding-bottom: 22px;
}

.logo h4 {
	margin-top: 0px;
	margin-bottom: 0px;
}

.logo img {
	margin-right: 10px;
	width: 40px;
	height: 40px;
}

.icon-edit {
	margin-left: auto;
 	 margin-right: 5px;
	cursor: pointer;
	font-size: 20px;
	color: #344054;
}

.menu,
.previous-menu {
	border-top: 1px solid #cdd7e2;
	padding-top: 20px;
}

.menu h2,
.previous-menu h2 {
	font-size: 16px;
	margin-bottom: 10px;
}

.menu ul,
.previous-menu ul {
	list-style: none;
	padding: 0;
}

.menu ul li,
.previous-menu ul li {
    padding: 10px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.menu ul li span,
.previous-menu ul li span {
    overflow: hidden;
    white-space: nowrap;
    flex: 1; 
    margin-right: 10px; 
}

.active {
	background-color: #F7F4FF;
	border-right: 3px solid #9057FD;
}

.chat-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    background: #f7f9fc;
    padding: 0 15px;
}

.inner-chat-section {
    background-color: #fff;
    display: flex;
    flex-direction: column;
    height: 100vh; 
    overflow: hidden;
}

.chat-header {
	padding-left: 20px;
	padding-top: 30px;
	padding-bottom: 35px;
	border-bottom: 1px solid #e0e0e0;
    padding: 32px 20px;
    border-bottom: 1px solid #e0e0e0;
    background-color: #ffffff;
}

.chat-title {
    font-size: 16px;
    font-weight: bold;
    color: #475569;
}

.chat-content {
    flex: 1;
    padding: 20px 15px 10px 50px;
    overflow-y: auto; 
    height: 100%;
}

.chat-footer-input {
    padding: 10px 0;
}

.chat-input {
    position: relative;
    display: flex;
    align-items: center;
	margin: 0 auto;
	width: 85%;
}


.chat-input .send-message {
    position: absolute;
    font-size: 20px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    height: 40px;
    width: 40px;
    background: #9057fd;
    border-radius: 50%;
    color: #fff;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.message {
	margin-bottom: 20px;
}

.message .user-info {
	position: relative;
}

.user-message {
	background-color: #F8FAFC;
}

.avatar-container {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	margin-right: 10px;
	background-color: #EEF2FF;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	color: #475569;
	position: absolute;
  }
  
  .avatar-image {
	width: 50%; 
	height: 50%;
	object-fit: contain;
  }
  

.message .user-info .user-info-image {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	margin-right: 10px;
}

.user-info .time-align {
	display: flex;
}

.user-info .time-align h4 {
	margin-top: 0px;
	margin-bottom: 0;
}

.message p {
	margin: 0;
	padding: 9px 15px;
	border-radius: 20px;
	display: inline-block;
	max-width: 100%;
	margin-top: 7px;
	line-height: 30px;
}

.our-message {
	margin-top: 6px;
	padding-right: 60px;
	margin-left: 60px;
}
.timestamp {
	font-size: 12px;
	color: #999;
	margin-left: 10px;
	margin-top: 4px;
}

.today_section {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 9px;
  padding-left: 10px;
}

.dropdown_count {
	color: #344054;
	display: flex;
	align-items: center;
}

.dropdown_count p {
	font-size: 15px;
	font-weight: 500;
	color: #344054;
}

.dropdown_count i {
	font-size: 20px;
	color: #344054;
	margin-bottom: 5px;
}

.chat-options {
	position: relative;
	display: inline-block;
	float: right !important;
}

.icon-more-horizontal {
	cursor: pointer;
}

.dropdown-menu {
	position: absolute;
	top: 20px;
	right: 0;
	background-color: white;
	border: 1px solid #ddd;
	padding: 5px;
	z-index: 1000;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-menu p {
	margin: 0;
	padding: 5px 10px;
	cursor: pointer;
}

.dropdown-menu p:hover {
	background-color: #f5f5f5;
}


// media quarry

@media (max-width:1250px){
	.sidebar{
		.logo{
			h4{
				font-size: 15px;
			}
		}
	}
}
@media (max-width:996px){
	.sidebar{
		.logo{
			h4{
				display: none;
			}
		}
	}
}
@media (max-width:768px){
	.sidebar{
		display: none;
		
	}
	.chat-section{
	.chat-content {
		padding: 20px 10px 10px 10px;
		.message{
			.user-info{
				.our-message{
					padding-right: 10px;
				}
			}
		}
	}
	.chat-header{
		padding: 20px 20px;
	}
	}
	.chat-footer-input{
		.chat-input .send-message{
			width: 30px;
			height	: 30px;

		}
	}

}