@import "./variables.scss";


.react-tabs {
  -webkit-tap-highlight-color: transparent;
  .card {
    position: relative;
    z-index: 1;
  }
}

.react-tabs__tab-list {
  margin: 0 0 20px;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  @media (max-width: 767px) {
    gap: 10px;
  }
}

.react-tabs__tab {
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: 600;
  padding: 14px 40px;
  cursor: pointer;
  display: inline-block;
  color: #585858;
  position: relative;
  border-radius: 10px;
  background: #F2FAFF;
  @media (min-width: 992px) and (max-width: 1399px) {
    padding: 12px 45px;
  }
  @media (max-width: 991px) {
    padding: 12px 20px;
  }
  @media (max-width: 767px) {
    padding: 10px 30px;
    font-size: 14px;
    border-radius: 12px;
  }
  &:focus {
    outline: none;
  }

  // &:after {
  //   content: '';
  //   background-image: url(/images/up_arrow_icon.png);
  //   background-size: contain;
  //   background-repeat: no-repeat;
  //   width: 30px;
  //   height: 18px;
  //   position: absolute;
  //   bottom: -21px;
  //   left: 50%;
  //   transform: translate(-50%, 0px);
  //   opacity: 0;
  //   @media (max-width: 767px) {
  //    content: none;
  //   }
  // }
  &--selected {
    background: $primary_gradient;
    color: $white;
    &:after {
      opacity: 1;
    }
  }
  &--disabled {
    opacity: .6;
    cursor: default;
  }
  
}

.sidetab .un-selected-artist {
  position: relative;
  padding-left: 10px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidetab .un-selected-artist::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: rgba(0, 0, 0, 0);
}

.sidetab .selected-artist {
  border-left: 2px solid #000;
  padding-left: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidetab .selected-artist img,
.sidetab .un-selected-artist img {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.sidetab .selected-artist span,
.sidetab .un-selected-artist span {
  max-width: calc(100% - 32px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidetab .selected-artist,
.sidetab .un-selected-artist {
  margin-left: 8px;
}

.noarrow {
  .react-tabs__tab {
    &--selected {
      &:after {
        opacity: 0;
      }
    }
  }
}
.react-tabs__tab-panel {
  display: none;
  &--selected {
    display: block;
  }
}