@import "../../variables.scss";

// Sidebar with canvas 

.main_sidebar {
  width: 80px;
  z-index: 11111;
  position: fixed;
  top: 100px;
  left: 0;
  transition: all .3s;
  @media (max-width: 991px) {
    width: 310px;
    bottom: 0;
    top: 0;
  }
  .sidewrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    background: #ffffff;
    padding: 12px;
    @media (max-width: 991px) {
      padding: 16px;
      padding-top: 20px;
      z-index: 111;
      position: relative;

    }
    .logo {
      cursor: pointer;
      padding-bottom: 30px;
      display: none;
      @media (max-width: 991px) {
        display: inline-block;
      }
      img {
        width: 140px;
        transition: all .3s;
      }
    }
    .menu {
      height: 100%;
      display: flex;
      flex: 1 1 0%;
      flex-direction: column;
      justify-content: space-between;
      overflow-x: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
      ul {
        padding: 0;
        list-style: none;
        margin: 0;
        li {
          margin-bottom: 2px;
          border-radius: 50px;
          a {
            display: block;
            font-size: 16px;
            color: $black;
            text-decoration: none;
            font-weight: 600;
            margin-bottom: 10px;
            border-radius: 4px;
            padding: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            gap: 10px;
            @media (min-width: 992px) {
              justify-content: center;
              gap: 0;
              font-size: 0;
            }
            @media (max-width: 991px) {
              color: #3C3C3C99;
              font-weight: 500;
              font-size: 14px;
            }
            .bgicon {
              margin-right: 0;
            }
            i {
              position: relative;
              vertical-align: middle;
              font-size: 22px;
              // top: -1px;
              position: relative;
              color: #3C3C3C;
              @media (max-width: 991px) {
                color: #3C3C3C99;
                font-size: 18px;
              }
            }
          }
          &.active {
            a {
              background: $color_primary;
              color: $white;
              .bgicon {
                i {
                  color: $white;
                }
              }
            }
          }
  
         
        }
      }
      .extra_menu {
        display: flex;
        align-items: center;
        gap: 20px;
        li {
          a {
            i {
              background: $primary_gradient;
              -webkit-text-fill-color: unset;
              color: #fff;
              padding: 8px;
              display: inline-block;
              border-radius: 10px;
              margin-right: 6px;
            }
          }
        }
      }
    }
    
  }


  .togglebtn {
    position: fixed;
    left: 10px;
    top: 18px;
    font-size: 28px;
    z-index: 1;
    color: #3C3C3C;
    cursor: pointer;
    background: transparent;
    border: none;
    padding: 0;
    display: none;
    &:focus, &:focus-visible {
      outline: 0;
    }
  }
  @media (max-width: 991px) {
    .togglebtn {
      display: block;
      left: 16px;
      top: 16px;
      @media (max-width: 767px) {
        top: 10px;
      }
    }
  }
  @media (max-width: 991px) {
    left: -320px;
    transition: all .6s;
    &.collapse {
      left: 0;
    }
    // &:not(.collapse) {
    //   .togglebtn {
    //     top: -20px;
    //     bottom: auto;
    //     right: -50px;
    //   }
    // }
  }
}