@import "../../variables.scss";
.searchbar {
  max-width: 600px;
  width: 100%;
  margin-top: -42px;
  position: relative;
  margin-left: 140px;


  @media (max-width: 767px) {
    margin-top: -35px;
    max-width: unset;
    width: auto;
    position: absolute;
    right: 10px;
    left: 55px;
    top: 42px;
  }

  &.active {
    .input_group {
      input {
        width: 100%;
        opacity: 1;
        padding-right: 44px;
      }
    }
  }

  .input_group {
    position: relative;
    
    input {
      border: none;
      border-radius: 6px;
      padding: 12px 14px;
      font-weight: 500;
      font-size: 14px;
      height: auto;
      width: 0;
      position: absolute;
      top: 0;
      right: 0;
      transition: all .6s;
      opacity: 0;
      background-color: #F0F2F5;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.0509803922);

      @media (max-width: 767px) {
        padding: 8px 10px;
      }

      @media (min-width: 768px) {
        width: 100%;
        opacity: 1;
        padding-left: 44px;
      }

      &:focus,
      &:focus-visible {
        outline: none;
      }
    }

    img {
      width: 30px;
      height: 30px;
      object-fit: contain;

      @media (max-width: 991px) {
        width: 20px;
        height: 20px;
      }
    }

    i {
      font-size: 24px;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .input_group_text {
      line-height: 1;
      position: absolute;
      left: 14px;
      top: 9px;
      cursor: pointer;

      @media (max-width: 767px) {
        right: 10px;
        top: 7px;
        left: auto;
      }
    }
  }

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    margin-top: 45px;
  }
  
  .dropdown_item {
    display: flex; /* Use flexbox to align items inline */
    align-items: center; /* Center align items vertically */
    padding: 10px;
    cursor: pointer;
  
    &:hover {
      background-color: #f1f1f1;
    }
  }
  
  .avatar_container {
    width: 40px; /* Adjust the size of the avatar */
    height: 40px; /* Adjust the size of the avatar */
    overflow: hidden;
    border-radius: 50%; /* Makes the image circular */
    margin-right: 10px;
  }
  
  .avatar {
    width: 100%;
    height: 100%; /* Ensure the image fills the container */
    object-fit: cover; /* Maintain aspect ratio and cover the container */
  }
  
  .artist_name {
    font-size: 16px;
    font-weight: bold;
  }
  
}
