@import "../../variables.scss";

$paddingY: 5vh;
$paddingX: 3.5vw;
section.prelogin {
  width: 100%;
  height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  // background: url(../../../images/logo.png) no-repeat;
  background-position: center;
  background-size: cover;
  padding: 20px;
  .inner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    > div {
      flex: 1;
      width: 100%;
      height: 96vh;
      padding: $paddingY $paddingX;
      min-height: 96vh;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 0;
      }
    }
    .innerWrapper {
      width: 550px;
      @media (max-width: 1199px) {
        width: 400px;
      }
      @media (max-width: 540px) {
        width: 330px;
      }
      @media (max-width: 400px) {
        width: 280px;
      }
      .logo {
        text-align: center;
        margin-bottom: 20px;
      }
    }
    form {
      &.form_wrapper {
        text-align: center;
        span {
          text-align: left;
          display: block;
        }
    
        .danger-color {
          color: #b42318 !important;
          margin-bottom: 15px;
        }
      }
    }
    
    
    .visualWrapper {
      img {
        display: block;
        width: 55%;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        object-fit: cover;
        object-position: bottom left;
      }
      @media (max-width: 991px) {
        display: none;
      }
    }
    .formWrapper {
      display: grid;
      align-items: center;
      justify-content: center;
      position: relative;
      border-radius: 30px;
      border: 2px solid #ffffff75;
      background: rgba(255, 255, 255, 0.30);
      backdrop-filter: blur(5px);
      input,select {
        color: $black;
      }
      input.is-invalid_boder {
        border: 1px solid #b42318 !important;
        background-image: none; 
      }
      .form_title {
        font-size: 40px;
        margin-bottom: 6px;
        color: $black;
        @media (max-width: 1399px){
          font-size: 34px;
        }
        @media (max-width: 767px) {
          font-size: 24px;
        }
      }
      .form_para {
        color: $black;
        margin-bottom: 20px;
        @media (max-width: 991px) {
          text-align: center;
        }
        a:visited {
          color: $dark;
        }
      }
      .forgotWrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        position: relative;
        a,
        label {
          font-size: 16px;
          color: $black;
          font-weight: 500;

        }        
      }
      button[type="submit"] {
        width: 65%;
        // margin-top: 2.5vh;
      }
      input[type="checkbox"] {
        width: 18px;
        height: 18px;
        cursor: pointer;
      }

      .extra_info {
        margin-top: 40px;
        font-size: 16px;
        color: $black;
        @media (max-width: 767px) {
          font-size: 14px;
          margin-top: 20px;
        }
        a {
          color: $dark;
          font-weight: 800;
        }
      }

      .form_brand {
        img {
          max-width: 250px;
          margin-bottom: 20px;
          @media (max-width: 1399px){
            max-width: 200px;
          }
          @media (max-width: 1199px){
            max-width: 150px;
            margin-bottom: 10px;
          }
        }
       
      }

      .vendorbtn {
        display: flex;
        gap: 15px;
        a {
          width: 50%;
          color: $black;
          padding: 12px 20px;
          display: flex;
          align-items: center;
          gap: 12px;
          border-radius: 16px;
          border: 2px solid #FFF;
          background: #0873BB;
          box-shadow: 10px 20px 40px 0px rgba(16, 46, 82, 0.24);
          font-size: 18px;
          text-align: left;
          font-weight: 600;
          line-height: 1;
          @media (max-width: 767px) {
            font-size: 16px;
            padding: 6px 12px;
            gap: 6px;
            border-radius: 10px;
          }
          &:nth-child(2) {
            background: #102E52;
          }
          span {
            font-weight: 400;
            display: block;
            line-height: 1;
            font-size: 12px;
            margin-bottom: 4px;
            @media (max-width: 767px) {
              font-size: 10px;
            }
          }
          img {
            width: auto;
            height: 40px;
            object-fit: contain;

          }
        }
      }
    }
  }
}

