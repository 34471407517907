@import "./mixins.scss";
@import "./grid.scss";
@import "./utilities.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

$red: #F31F1F;
$black: #000000;
$dark: #102E52;
$white: #ffffff;
$grey: #9E9E9E;
$light_grey: #C2C2C2;
$light_grey2: #F3F9F9;
$color_primary: #9057FD;
$color_secondary: #232C33;
$primary_gradient: linear-gradient(270deg, #0F5998 4.84%, #21A0E7 94.29%);
$primary_shadow: 8px 8px 24px 0px #0F5998;
$sidebar_width: 80px;
$theme_font : "Inter", sans-serif;

// DESIGN CONSTANT
$light_shadow: inset 0px 15px 15px #EEEEF6;

// FROM CONTROL CONSTANTS
$fmc_borderRadius: 50px;

.fs-18 {font-size: 18px !important;}
.fs-16 {font-size: 16px !important;}
.fs-14 {font-size: 14px !important;}
.fs-12 {font-size: 12px !important;}
.fs-10 {font-size: 10px !important;}
.fs-8 {font-size: 8px !important;}
.fs-6 {font-size: 6px !important;}

