
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}

.col {
  flex: 1 0 0%;
  &-auto {
    flex: 0 0 auto;
    width: auto;
  }
  &-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  &-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  &-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  &-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  &-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  &-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  &-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  &-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  &-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  &-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  &-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  &-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.offset {
  &-1 {
    margin-left: 8.33333333%;
  }
  &-2 {
    margin-left: 16.66666667%;
  }
  &-3 {
    margin-left: 25%;
  }
  &-4 {
    margin-left: 33.33333333%;
  }
  &-5 {
    margin-left: 41.66666667%;
  }
  &-6 {
    margin-left: 50%;
  }
  &-7 {
    margin-left: 58.33333333%;
  }
  &-8 {
    margin-left: 66.66666667%;
  }
  &-9 {
    margin-left: 75%;
  }
  &-10 {
    margin-left: 83.33333333%;
  }
  &-11 {
    margin-left: 91.66666667%;
  }
} 

@media (min-width: 576px) {
  .col {
    &-sm {
      flex: 1 0 0%;
    }
    &-sm-auto {
      flex: 0 0 auto;
      width: auto;
    }
    &-sm-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    &-sm-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    &-sm-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    &-sm-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    &-sm-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    &-sm-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    &-sm-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    &-sm-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    &-sm-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    &-sm-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    &-sm-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    &-sm-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  } 

  .offset {
    &-sm-0 {
      margin-left: 0;
    }
    &-sm-1 {
      margin-left: 8.33333333%;
    }
    &-sm-2 {
      margin-left: 16.66666667%;
    }
    &-sm-3 {
      margin-left: 25%;
    }
    &-sm-4 {
      margin-left: 33.33333333%;
    }
    &-sm-5 {
      margin-left: 41.66666667%;
    }
    &-sm-6 {
      margin-left: 50%;
    }
    &-sm-7 {
      margin-left: 58.33333333%;
    }
    &-sm-8 {
      margin-left: 66.66666667%;
    }
    &-sm-9 {
      margin-left: 75%;
    }
    &-sm-10 {
      margin-left: 83.33333333%;
    }
    &-sm-11 {
      margin-left: 91.66666667%;
    }
  } 
}
@media (min-width: 768px) {
  .col {
    &-md {
      flex: 1 0 0%;
    }
    &-md-auto {
      flex: 0 0 auto;
      width: auto;
    }
    &-md-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    &-md-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    &-md-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    &-md-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    &-md-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    &-md-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    &-md-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    &-md-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    &-md-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    &-md-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    &-md-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    &-md-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  } 

  .offset {
    &-md-0 {
      margin-left: 0;
    }
    &-md-1 {
      margin-left: 8.33333333%;
    }
    &-md-2 {
      margin-left: 16.66666667%;
    }
    &-md-3 {
      margin-left: 25%;
    }
    &-md-4 {
      margin-left: 33.33333333%;
    }
    &-md-5 {
      margin-left: 41.66666667%;
    }
    &-md-6 {
      margin-left: 50%;
    }
    &-md-7 {
      margin-left: 58.33333333%;
    }
    &-md-8 {
      margin-left: 66.66666667%;
    }
    &-md-9 {
      margin-left: 75%;
    }
    &-md-10 {
      margin-left: 83.33333333%;
    }
    &-md-11 {
      margin-left: 91.66666667%;
    }
  } 
}
@media (min-width: 992px) {
  .col {
    &-lg {
      flex: 1 0 0%;
    }
    &-lg-auto {
      flex: 0 0 auto;
      width: auto;
    }
    &-lg-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    &-lg-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    &-lg-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    &-lg-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    &-lg-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    &-lg-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    &-lg-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    &-lg-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    &-lg-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    &-lg-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    &-lg-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    &-lg-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  } 
  .offset {
    &-lg-0 {
      margin-left: 0;
    }
    &-lg-1 {
      margin-left: 8.33333333%;
    }
    &-lg-2 {
      margin-left: 16.66666667%;
    }
    &-lg-3 {
      margin-left: 25%;
    }
    &-lg-4 {
      margin-left: 33.33333333%;
    }
    &-lg-5 {
      margin-left: 41.66666667%;
    }
    &-lg-6 {
      margin-left: 50%;
    }
    &-lg-7 {
      margin-left: 58.33333333%;
    }
    &-lg-8 {
      margin-left: 66.66666667%;
    }
    &-lg-9 {
      margin-left: 75%;
    }
    &-lg-10 {
      margin-left: 83.33333333%;
    }
    &-lg-11 {
      margin-left: 91.66666667%;
    }
  } 
}
@media (min-width: 1200px) {
  .col {
    &-xl {
      flex: 1 0 0%;
    }
    &-xl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    &-xl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    &-xl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    &-xl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    &-xl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    &-xl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    &-xl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    &-xl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    &-xl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    &-xl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    &-xl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    &-xl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    &-xl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  } 
  .offset {
    &-xl-0 {
      margin-left: 0;
    }
    &-xl-1 {
      margin-left: 8.33333333%;
    }
    &-xl-2 {
      margin-left: 16.66666667%;
    }
    &-xl-3 {
      margin-left: 25%;
    }
    &-xl-4 {
      margin-left: 33.33333333%;
    }
    &-xl-5 {
      margin-left: 41.66666667%;
    }
    &-xl-6 {
      margin-left: 50%;
    }
    &-xl-7 {
      margin-left: 58.33333333%;
    }
    &-xl-8 {
      margin-left: 66.66666667%;
    }
    &-xl-9 {
      margin-left: 75%;
    }
    &-xl-10 {
      margin-left: 83.33333333%;
    }
    &-xl-11 {
      margin-left: 91.66666667%;
    }
  } 
}
@media (min-width: 1400px) {
  .col {
    &-xxl {
      flex: 1 0 0%;
    }
    &-xxl-auto {
      flex: 0 0 auto;
      width: auto;
    }
    &-xxl-1 {
      flex: 0 0 auto;
      width: 8.33333333%;
    }
    &-xxl-2 {
      flex: 0 0 auto;
      width: 16.66666667%;
    }
    &-xxl-3 {
      flex: 0 0 auto;
      width: 25%;
    }
    &-xxl-4 {
      flex: 0 0 auto;
      width: 33.33333333%;
    }
    &-xxl-5 {
      flex: 0 0 auto;
      width: 41.66666667%;
    }
    &-xxl-6 {
      flex: 0 0 auto;
      width: 50%;
    }
    &-xxl-7 {
      flex: 0 0 auto;
      width: 58.33333333%;
    }
    &-xxl-8 {
      flex: 0 0 auto;
      width: 66.66666667%;
    }
    &-xxl-9 {
      flex: 0 0 auto;
      width: 75%;
    }
    &-xxl-10 {
      flex: 0 0 auto;
      width: 83.33333333%;
    }
    &-xxl-11 {
      flex: 0 0 auto;
      width: 91.66666667%;
    }
    &-xxl-12 {
      flex: 0 0 auto;
      width: 100%;
    }
  } 
  .offset {
    &-xxl-0 {
      margin-left: 0;
    }
    &-xxl-1 {
      margin-left: 8.33333333%;
    }
    &-xxl-2 {
      margin-left: 16.66666667%;
    }
    &-xxl-3 {
      margin-left: 25%;
    }
    &-xxl-4 {
      margin-left: 33.33333333%;
    }
    &-xxl-5 {
      margin-left: 41.66666667%;
    }
    &-xxl-6 {
      margin-left: 50%;
    }
    &-xxl-7 {
      margin-left: 58.33333333%;
    }
    &-xxl-8 {
      margin-left: 66.66666667%;
    }
    &-xxl-9 {
      margin-left: 75%;
    }
    &-xxl-10 {
      margin-left: 83.33333333%;
    }
    &-xxl-11 {
      margin-left: 91.66666667%;
    }
  } 
}


// Grid system
//
// Generate semantic grid columns with these mixins.

@mixin make-row($gutter: $grid-gutter-width) {
  --#{$variable-prefix}gutter-x: #{$gutter};
  --#{$variable-prefix}gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--#{$variable-prefix}gutter-y) * -1); // stylelint-disable-line function-disallowed-list
  margin-right: calc(var(--#{$variable-prefix}gutter-x) * -.5); // stylelint-disable-line function-disallowed-list
  margin-left: calc(var(--#{$variable-prefix}gutter-x) * -.5); // stylelint-disable-line function-disallowed-list
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  // Add box sizing if only the grid is loaded
  box-sizing: if(variable-exists(include-column-box-sizing) and $include-column-box-sizing, border-box, null);
  // Prevent columns from becoming too narrow when at smaller grid tiers by
  // always setting `width: 100%;`. This works because we set the width
  // later on to override this initial width.
  flex-shrink: 0;
  width: 100%;
  max-width: 100%; // Prevent `.col-auto`, `.col` (& responsive variants) from breaking out the grid
  padding-right: calc(var(--#{$variable-prefix}gutter-x) * .5); // stylelint-disable-line function-disallowed-list
  padding-left: calc(var(--#{$variable-prefix}gutter-x) * .5); // stylelint-disable-line function-disallowed-list
  margin-top: var(--#{$variable-prefix}gutter-y);
}

@mixin make-col($size: false, $columns: $grid-columns) {
  @if $size {
    flex: 0 0 auto;
    width: percentage(divide($size, $columns));

  } @else {
    flex: 1 1 0;
    max-width: 100%;
  }
}

@mixin make-col-auto() {
  flex: 0 0 auto;
  width: auto;
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: divide($size, $columns);
  margin-left: if($num == 0, 0, percentage($num));
}

// Row columns
//
// Specify on a parent element(e.g., .row) to force immediate children into NN
// numberof columns. Supports wrapping to new lines, but does not do a Masonry
// style grid.
@mixin row-cols($count) {
  > * {
    flex: 0 0 auto;
    width: divide(100%, $count);
  }
}

// Framework grid generation
//
// Used only by Bootstrap to generate the correct number of grid classes given
// any value of `$grid-columns`.

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    // .row-cols defaults must all appear before .col overrides so they can be overridden.
    $infix: breakpoint-infix($breakpoint, $breakpoints);
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Provide basic `.col-{bp}` classes for equal-width flexbox columns
      .col#{$infix} {
        flex: 1 0 0%; // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      }

      .row-cols#{$infix}-auto > * {
        @include make-col-auto();
      }

      @if $grid-row-columns > 0 {
        @for $i from 1 through $grid-row-columns {
          .row-cols#{$infix}-#{$i} {
            @include row-cols($i);
          }
        }
      }
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      .col#{$infix}-auto {
        @include make-col-auto();
      }

      @if $columns > 0 {
        @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
            @include make-col($i, $columns);
          }
        }

        // `$columns - 1` because offsetting by the width of an entire row isn't possible
        @for $i from 0 through ($columns - 1) {
          @if not ($infix == "" and $i == 0) { // Avoid emitting useless .offset-0
            .offset#{$infix}-#{$i} {
              @include make-col-offset($i, $columns);
            }
          }
        }
      }

      // Gutters
      //
      // Make use of `.g-*`, `.gx-*` or `.gy-*` utilities to change spacing between the columns.
      @each $key, $value in $gutters {
        .g#{$infix}-#{$key},
        .gx#{$infix}-#{$key} {
          --#{$variable-prefix}gutter-x: #{$value};
        }

        .g#{$infix}-#{$key},
        .gy#{$infix}-#{$key} {
          --#{$variable-prefix}gutter-y: #{$value};
        }
      }
    }
  }
}
