@mixin form-control {
  font-size: 16px;
  font-weight: 500;
  color: #222;
  outline: 0;
  display: block;
  width: 100%;
  padding: 15px 18px;
  transition: all 0.3s;
  border-radius: 10px;
  border: 1px solid #C2C2C2;
  background-color: #FFF;
  &:focus {
      border-radius: 10px;
      border: 1px solid $color_primary;
      background-color: #FFF;
      box-shadow: 4px 8px 16px 0px rgba(0, 0, 0, 0.16);
  }
  &::placeholder {
    color: $grey;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    padding: 10px 16px;
  }
}

@mixin form-control-shape {
  font-size: 14px;
  font-weight: 500;
  color: #222;
  outline: 0;
  display: block;
  width: 100%;
  padding: 12px 16px;
  transition: all 0.3s;
  border-radius: 6px;
  border: none;
  background-color: #F0F2F5;
  box-shadow: 0px 1px 2px 0px #1018280D;
  &:focus {
      border-radius: 6px;
      border: none;
      background-color: #F0F2F5;
  }
  &::placeholder {
    color: $grey;
  }
  @media (max-width: 767px) {
    font-size: 12px;
    padding: 10px 16px;
  }
}