@import "../../variables.scss";


.dashboard_layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 100vh;

  .main_content {
    width: 100%;
    transition: all .3s;
    padding-top: 76px;
    @media (min-width: 1200px) {
      padding-left: $sidebar_width;
    }
    @media (min-width: 992px ) and (max-width: 1199px) {
      padding-left: 90px;
    }
    @media (max-width: 991px ) {
      padding-top: 64px;
    }
    @media (max-width: 767px ) {
      padding-top: 50px;
    }
    
    .main_header {
      padding: 12px 18px;
      background: $white;
      border-bottom: 1px solid #E4E7EC;
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      z-index: 111;
      display: flex;
      align-items: center;
      gap: 50px;
     
      .logo {
        margin-right: 20px;
        cursor: pointer;
        img {
          width: 37px;
          transition: all .3s;
          position: relative;
          top: 2px;
          @media (max-width: 767px) {
            width: 100px;
            margin: auto;
          }
        }
        @media (max-width: 991px) {
          margin-left: 40px;
        }
        @media (max-width: 767px) {
          margin-right: 0;
          margin-left: 0;
        }
      }
      .headflex {
          display: flex;
          justify-content: end;
          align-items: center;
          gap: 16px;
      }
      .mid {
        display: flex;
        align-items: center;
        gap: 20px;
        @media (max-width: 1299px) and (min-width: 1200px) {
          gap: 10px;
        }
      }
      .title {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
        white-space: nowrap;
        @media (max-width: 767px) {
         display: none;
        }
      }
      @media (max-width: 767px) {
        height: 50px;
        display: block;
      }
    }
    .scroll_content {
      padding: 24px;
      @media (max-width: 991px) {
        padding: 0;
      }
    }
  }
}
