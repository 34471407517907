.d {
  &-inline {
    display: inline !important
  }
  &-inline-block {
    display: inline-block !important
  }
  &-block {
    display: block !important
  }
  &-grid {
    display: grid !important
  }
  &-flex {
    display: flex !important
  }
  &-inline-flex {
    display: inline-flex !important
  }
  &-none {
    display: none !important
  }
  @media (min-width:1400px) {
    &-xxl-inline {
      display: inline !important
    }
    &-xxl-inline-block {
      display: inline-block !important
    }
    &-xxl-block {
      display: block !important
    }
    &-xxl-grid {
      display: grid !important
    }
    &-xxl-flex {
      display: flex !important
    }
    &-xxl-inline-flex {
      display: inline-flex !important
    }
    &-xxl-none {
      display: none !important
    }
  }
  @media (min-width:1200px) {
    &-xl-inline {
      display: inline !important
    }
    &-xl-inline-block {
      display: inline-block !important
    }
    &-xl-block {
      display: block !important
    }
    &-xl-grid {
      display: grid !important
    }
    &-xl-flex {
      display: flex !important
    }
    &-xl-inline-flex {
      display: inline-flex !important
    }
    &-xl-none {
      display: none !important
    }
  }
  @media (min-width:992px) {
    &-lg-inline {
      display: inline !important
    }
    &-lg-inline-block {
      display: inline-block !important
    }
    &-lg-block {
      display: block !important
    }
    &-lg-grid {
      display: grid !important
    }
    &-lg-flex {
      display: flex !important
    }
    &-lg-inline-flex {
      display: inline-flex !important
    }
    &-lg-none {
      display: none !important
    }
  }
  @media (min-width:768px) {
    &-md-inline {
      display: inline !important
    }
    &-md-inline-block {
      display: inline-block !important
    }
    &-md-block {
      display: block !important
    }
    &-md-grid {
      display: grid !important
    }
    &-md-flex {
      display: flex !important
    }
    &-md-inline-flex {
      display: inline-flex !important
    }
    &-md-none {
      display: none !important
    }
  }
  @media (min-width:576px) {
    &-sm-inline {
      display: inline !important
    }
    &-sm-inline-block {
      display: inline-block !important
    }
    &-sm-block {
      display: block !important
    }
    &-sm-grid {
      display: grid !important
    }
    &-sm-flex {
      display: flex !important
    }
    &-sm-inline-flex {
      display: inline-flex !important
    }
    &-sm-none {
      display: none !important
    }
  }
} 


.m {
  &-0 {
    margin: 0 !important;
  }
  &-1 {
    margin: 0.25rem !important;
  }
  
  &-2 {
    margin: 0.5rem !important;
  }
  
  &-3 {
    margin: 1rem !important;
  }
  
  &-4 {
    margin: 1.5rem !important;
  }
  
  &-5 {
    margin: 3rem !important;
  }
  
  &-auto {
    margin: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin: 0 !important;
    }
    &-xxl-1 {
      margin: 0.25rem !important;
    }
    &-xxl-2 {
      margin: 0.5rem !important;
    }
    &-xxl-3 {
      margin: 1rem !important;
    }
    &-xxl-4 {
      margin: 1.5rem !important;
    }
    &-xxl-5 {
      margin: 3rem !important;
    }
    &-xxl-auto {
      margin: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin: 0 !important;
    }
    &-xl-1 {
      margin: 0.25rem !important;
    }
    &-xl-2 {
      margin: 0.5rem !important;
    }
    &-xl-3 {
      margin: 1rem !important;
    }
    &-xl-4 {
      margin: 1.5rem !important;
    }
    &-xl-5 {
      margin: 3rem !important;
    }
    &-xl-auto {
      margin: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin: 0 !important;
    }
    &-lg-1 {
      margin: 0.25rem !important;
    }
    &-lg-2 {
      margin: 0.5rem !important;
    }
    &-lg-3 {
      margin: 1rem !important;
    }
    &-lg-4 {
      margin: 1.5rem !important;
    }
    &-lg-5 {
      margin: 3rem !important;
    }
    &-lg-auto {
      margin: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin: 0 !important;
    }
    &-md-1 {
      margin: 0.25rem !important;
    }
    &-md-2 {
      margin: 0.5rem !important;
    }
    &-md-3 {
      margin: 1rem !important;
    }
    &-md-4 {
      margin: 1.5rem !important;
    }
    &-md-5 {
      margin: 3rem !important;
    }
    &-md-auto {
      margin: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin: 0 !important;
    }
    &-sm-1 {
      margin: 0.25rem !important;
    }
    &-sm-2 {
      margin: 0.5rem !important;
    }
    &-sm-3 {
      margin: 1rem !important;
    }
    &-sm-4 {
      margin: 1.5rem !important;
    }
    &-sm-5 {
      margin: 3rem !important;
    }
    &-sm-auto {
      margin: auto !important;
    }
  }
} 

.mx {
  &-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  &-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }
  
  &-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
  
  &-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }
  
  &-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }
  
  &-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }
  
  &-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-xxl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    &-xxl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    &-xxl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    &-xxl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    &-xxl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    &-xxl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-xl-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    &-xl-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    &-xl-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    &-xl-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    &-xl-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    &-xl-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-lg-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    &-lg-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    &-lg-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    &-lg-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    &-lg-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    &-lg-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-md-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    &-md-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    &-md-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    &-md-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    &-md-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    &-md-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin-right: 0 !important;
      margin-left: 0 !important;
    }
    &-sm-1 {
      margin-right: 0.25rem !important;
      margin-left: 0.25rem !important;
    }
    &-sm-2 {
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
    &-sm-3 {
      margin-right: 1rem !important;
      margin-left: 1rem !important;
    }
    &-sm-4 {
      margin-right: 1.5rem !important;
      margin-left: 1.5rem !important;
    }
    &-sm-5 {
      margin-right: 3rem !important;
      margin-left: 3rem !important;
    }
    &-sm-auto {
      margin-right: auto !important;
      margin-left: auto !important;
    }
  }
} 

.my {
  &-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  &-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  &-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  &-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  &-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  &-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &-xxl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    &-xxl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    &-xxl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &-xxl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    &-xxl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    &-xxl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &-xl-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    &-xl-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    &-xl-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &-xl-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    &-xl-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    &-xl-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &-lg-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    &-lg-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    &-lg-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &-lg-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    &-lg-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    &-lg-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &-md-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    &-md-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    &-md-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &-md-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    &-md-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    &-md-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    &-sm-1 {
      margin-top: 0.25rem !important;
      margin-bottom: 0.25rem !important;
    }
    &-sm-2 {
      margin-top: 0.5rem !important;
      margin-bottom: 0.5rem !important;
    }
    &-sm-3 {
      margin-top: 1rem !important;
      margin-bottom: 1rem !important;
    }
    &-sm-4 {
      margin-top: 1.5rem !important;
      margin-bottom: 1.5rem !important;
    }
    &-sm-5 {
      margin-top: 3rem !important;
      margin-bottom: 3rem !important;
    }
    &-sm-auto {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }
  }
}

.mt {
  &-0 {
    margin-top: 0 !important;
  }
  &-1 {
    margin-top: 0.25rem !important;
  }
  &-2 {
    margin-top: 0.5rem !important;
  }
  &-3 {
    margin-top: 1rem !important;
  }
  &-4 {
    margin-top: 1.5rem !important;
  }
  &-5 {
    margin-top: 3rem !important;
  }
  &-auto {
    margin-top: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin-top: 0 !important;
    }
    &-xxl-1 {
      margin-top: 0.25rem !important;
    }
    &-xxl-2 {
      margin-top: 0.5rem !important;
    }
    &-xxl-3 {
      margin-top: 1rem !important;
    }
    &-xxl-4 {
      margin-top: 1.5rem !important;
    }
    &-xxl-5 {
      margin-top: 3rem !important;
    }
    &-xxl-auto {
      margin-top: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin-top: 0 !important;
    }
    &-xl-1 {
      margin-top: 0.25rem !important;
    }
    &-xl-2 {
      margin-top: 0.5rem !important;
    }
    &-xl-3 {
      margin-top: 1rem !important;
    }
    &-xl-4 {
      margin-top: 1.5rem !important;
    }
    &-xl-5 {
      margin-top: 3rem !important;
    }
    &-xl-auto {
      margin-top: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin-top: 0 !important;
    }
    &-lg-1 {
      margin-top: 0.25rem !important;
    }
    &-lg-2 {
      margin-top: 0.5rem !important;
    }
    &-lg-3 {
      margin-top: 1rem !important;
    }
    &-lg-4 {
      margin-top: 1.5rem !important;
    }
    &-lg-5 {
      margin-top: 3rem !important;
    }
    &-lg-auto {
      margin-top: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin-top: 0 !important;
    }
    &-md-1 {
      margin-top: 0.25rem !important;
    }
    &-md-2 {
      margin-top: 0.5rem !important;
    }
    &-md-3 {
      margin-top: 1rem !important;
    }
    &-md-4 {
      margin-top: 1.5rem !important;
    }
    &-md-5 {
      margin-top: 3rem !important;
    }
    &-md-auto {
      margin-top: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin-top: 0 !important;
    }
    &-sm-1 {
      margin-top: 0.25rem !important;
    }
    &-sm-2 {
      margin-top: 0.5rem !important;
    }
    &-sm-3 {
      margin-top: 1rem !important;
    }
    &-sm-4 {
      margin-top: 1.5rem !important;
    }
    &-sm-5 {
      margin-top: 3rem !important;
    }
    &-sm-auto {
      margin-top: auto !important;
    }
  }
}

.me {
  &-0 {
    margin-right: 0 !important;
  }
  &-1 {
    margin-right: 0.25rem !important;
  }
  &-2 {
    margin-right: 0.5rem !important;
  }
  &-3 {
    margin-right: 1rem !important;
  }
  &-4 {
    margin-right: 1.5rem !important;
  }
  &-5 {
    margin-right: 3rem !important;
  }
  &-auto {
    margin-right: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin-right: 0 !important;
    }
    &-xxl-1 {
      margin-right: 0.25rem !important;
    }
    &-xxl-2 {
      margin-right: 0.5rem !important;
    }
    &-xxl-3 {
      margin-right: 1rem !important;
    }
    &-xxl-4 {
      margin-right: 1.5rem !important;
    }
    &-xxl-5 {
      margin-right: 3rem !important;
    }
    &-xxl-auto {
      margin-right: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin-right: 0 !important;
    }
    &-xl-1 {
      margin-right: 0.25rem !important;
    }
    &-xl-2 {
      margin-right: 0.5rem !important;
    }
    &-xl-3 {
      margin-right: 1rem !important;
    }
    &-xl-4 {
      margin-right: 1.5rem !important;
    }
    &-xl-5 {
      margin-right: 3rem !important;
    }
    &-xl-auto {
      margin-right: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin-right: 0 !important;
    }
    &-lg-1 {
      margin-right: 0.25rem !important;
    }
    &-lg-2 {
      margin-right: 0.5rem !important;
    }
    &-lg-3 {
      margin-right: 1rem !important;
    }
    &-lg-4 {
      margin-right: 1.5rem !important;
    }
    &-lg-5 {
      margin-right: 3rem !important;
    }
    &-lg-auto {
      margin-right: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin-right: 0 !important;
    }
    &-md-1 {
      margin-right: 0.25rem !important;
    }
    &-md-2 {
      margin-right: 0.5rem !important;
    }
    &-md-3 {
      margin-right: 1rem !important;
    }
    &-md-4 {
      margin-right: 1.5rem !important;
    }
    &-md-5 {
      margin-right: 3rem !important;
    }
    &-md-auto {
      margin-right: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin-right: 0 !important;
    }
    &-sm-1 {
      margin-right: 0.25rem !important;
    }
    &-sm-2 {
      margin-right: 0.5rem !important;
    }
    &-sm-3 {
      margin-right: 1rem !important;
    }
    &-sm-4 {
      margin-right: 1.5rem !important;
    }
    &-sm-5 {
      margin-right: 3rem !important;
    }
    &-sm-auto {
      margin-right: auto !important;
    }
  }
} 

.mb {
  &-0 {
    margin-bottom: 0 !important;
  }
  &-1 {
    margin-bottom: 0.25rem !important;
  }
  &-2 {
    margin-bottom: 0.5rem !important;
  }
  &-3 {
    margin-bottom: 1rem !important;
  }
  &-4 {
    margin-bottom: 1.5rem !important;
  }
  &-5 {
    margin-bottom: 3rem !important;
  }
  &-auto {
    margin-bottom: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin-bottom: 0 !important;
    }
    &-xxl-1 {
      margin-bottom: 0.25rem !important;
    }
    &-xxl-2 {
      margin-bottom: 0.5rem !important;
    }
    &-xxl-3 {
      margin-bottom: 1rem !important;
    }
    &-xxl-4 {
      margin-bottom: 1.5rem !important;
    }
    &-xxl-5 {
      margin-bottom: 3rem !important;
    }
    &-xxl-auto {
      margin-bottom: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin-bottom: 0 !important;
    }
    &-xl-1 {
      margin-bottom: 0.25rem !important;
    }
    &-xl-2 {
      margin-bottom: 0.5rem !important;
    }
    &-xl-3 {
      margin-bottom: 1rem !important;
    }
    &-xl-4 {
      margin-bottom: 1.5rem !important;
    }
    &-xl-5 {
      margin-bottom: 3rem !important;
    }
    &-xl-auto {
      margin-bottom: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin-bottom: 0 !important;
    }
    &-lg-1 {
      margin-bottom: 0.25rem !important;
    }
    &-lg-2 {
      margin-bottom: 0.5rem !important;
    }
    &-lg-3 {
      margin-bottom: 1rem !important;
    }
    &-lg-4 {
      margin-bottom: 1.5rem !important;
    }
    &-lg-5 {
      margin-bottom: 3rem !important;
    }
    &-lg-auto {
      margin-bottom: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin-bottom: 0 !important;
    }
    &-md-1 {
      margin-bottom: 0.25rem !important;
    }
    &-md-2 {
      margin-bottom: 0.5rem !important;
    }
    &-md-3 {
      margin-bottom: 1rem !important;
    }
    &-md-4 {
      margin-bottom: 1.5rem !important;
    }
    &-md-5 {
      margin-bottom: 3rem !important;
    }
    &-md-auto {
      margin-bottom: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin-bottom: 0 !important;
    }
    &-sm-1 {
      margin-bottom: 0.25rem !important;
    }
    &-sm-2 {
      margin-bottom: 0.5rem !important;
    }
    &-sm-3 {
      margin-bottom: 1rem !important;
    }
    &-sm-4 {
      margin-bottom: 1.5rem !important;
    }
    &-sm-5 {
      margin-bottom: 3rem !important;
    }
    &-sm-auto {
      margin-bottom: auto !important;
    }
  }
}

.ms {
  &-0 {
    margin-left: 0 !important;
  }
  &-1 {
    margin-left: 0.25rem !important;
  }
  &-2 {
    margin-left: 0.5rem !important;
  }
  &-3 {
    margin-left: 1rem !important;
  }
  &-4 {
    margin-left: 1.5rem !important;
  }
  &-5 {
    margin-left: 3rem !important;
  }
  &-auto {
    margin-left: auto !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      margin-left: 0 !important;
    }
    &-xxl-1 {
      margin-left: 0.25rem !important;
    }
    &-xxl-2 {
      margin-left: 0.5rem !important;
    }
    &-xxl-3 {
      margin-left: 1rem !important;
    }
    &-xxl-4 {
      margin-left: 1.5rem !important;
    }
    &-xxl-5 {
      margin-left: 3rem !important;
    }
    &-xxl-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      margin-left: 0 !important;
    }
    &-xl-1 {
      margin-left: 0.25rem !important;
    }
    &-xl-2 {
      margin-left: 0.5rem !important;
    }
    &-xl-3 {
      margin-left: 1rem !important;
    }
    &-xl-4 {
      margin-left: 1.5rem !important;
    }
    &-xl-5 {
      margin-left: 3rem !important;
    }
    &-xl-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      margin-left: 0 !important;
    }
    &-lg-1 {
      margin-left: 0.25rem !important;
    }
    &-lg-2 {
      margin-left: 0.5rem !important;
    }
    &-lg-3 {
      margin-left: 1rem !important;
    }
    &-lg-4 {
      margin-left: 1.5rem !important;
    }
    &-lg-5 {
      margin-left: 3rem !important;
    }
    &-lg-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      margin-left: 0 !important;
    }
    &-md-1 {
      margin-left: 0.25rem !important;
    }
    &-md-2 {
      margin-left: 0.5rem !important;
    }
    &-md-3 {
      margin-left: 1rem !important;
    }
    &-md-4 {
      margin-left: 1.5rem !important;
    }
    &-md-5 {
      margin-left: 3rem !important;
    }
    &-md-auto {
      margin-left: auto !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      margin-left: 0 !important;
    }
    &-sm-1 {
      margin-left: 0.25rem !important;
    }
    &-sm-2 {
      margin-left: 0.5rem !important;
    }
    &-sm-3 {
      margin-left: 1rem !important;
    }
    &-sm-4 {
      margin-left: 1.5rem !important;
    }
    &-sm-5 {
      margin-left: 3rem !important;
    }
    &-sm-auto {
      margin-left: auto !important;
    }
  }
}

.p {
  &-0 {
    padding: 0 !important;
  }
  &-1 {
    padding: 0.25rem !important;
  }
  &-2 {
    padding: 0.5rem !important;
  }
  &-3 {
    padding: 1rem !important;
  }
  &-4 {
    padding: 1.5rem !important;
  }
  &-5 {
    padding: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding: 0 !important;
    }
    &-xxl-1 {
      padding: 0.25rem !important;
    }
    &-xxl-2 {
      padding: 0.5rem !important;
    }
    &-xxl-3 {
      padding: 1rem !important;
    }
    &-xxl-4 {
      padding: 1.5rem !important;
    }
    &-xxl-5 {
      padding: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding: 0 !important;
    }
    &-xl-1 {
      padding: 0.25rem !important;
    }
    &-xl-2 {
      padding: 0.5rem !important;
    }
    &-xl-3 {
      padding: 1rem !important;
    }
    &-xl-4 {
      padding: 1.5rem !important;
    }
    &-xl-5 {
      padding: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding: 0 !important;
    }
    &-lg-1 {
      padding: 0.25rem !important;
    }
    &-lg-2 {
      padding: 0.5rem !important;
    }
    &-lg-3 {
      padding: 1rem !important;
    }
    &-lg-4 {
      padding: 1.5rem !important;
    }
    &-lg-5 {
      padding: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding: 0 !important;
    }
    &-md-1 {
      padding: 0.25rem !important;
    }
    &-md-2 {
      padding: 0.5rem !important;
    }
    &-md-3 {
      padding: 1rem !important;
    }
    &-md-4 {
      padding: 1.5rem !important;
    }
    &-md-5 {
      padding: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding: 0 !important;
    }
    &-sm-1 {
      padding: 0.25rem !important;
    }
    &-sm-2 {
      padding: 0.5rem !important;
    }
    &-sm-3 {
      padding: 1rem !important;
    }
    &-sm-4 {
      padding: 1.5rem !important;
    }
    &-sm-5 {
      padding: 3rem !important;
    }
  }
} 

.px {
  &-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  &-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }
  &-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }
  &-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }
  &-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }
  &-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &-xxl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    &-xxl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    &-xxl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    &-xxl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    &-xxl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &-xl-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    &-xl-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    &-xl-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    &-xl-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    &-xl-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &-lg-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    &-lg-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    &-lg-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    &-lg-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    &-lg-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &-md-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    &-md-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    &-md-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    &-md-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    &-md-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
    &-sm-1 {
      padding-right: 0.25rem !important;
      padding-left: 0.25rem !important;
    }
    &-sm-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
    }
    &-sm-3 {
      padding-right: 1rem !important;
      padding-left: 1rem !important;
    }
    &-sm-4 {
      padding-right: 1.5rem !important;
      padding-left: 1.5rem !important;
    }
    &-sm-5 {
      padding-right: 3rem !important;
      padding-left: 3rem !important;
    }
  }
} 

.py {
  &-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  &-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  &-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  &-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  &-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-xxl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    &-xxl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    &-xxl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    &-xxl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    &-xxl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-xl-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    &-xl-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    &-xl-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    &-xl-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    &-xl-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-lg-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    &-lg-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    &-lg-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    &-lg-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    &-lg-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-md-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    &-md-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    &-md-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    &-md-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    &-md-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    &-sm-1 {
      padding-top: 0.25rem !important;
      padding-bottom: 0.25rem !important;
    }
    &-sm-2 {
      padding-top: 0.5rem !important;
      padding-bottom: 0.5rem !important;
    }
    &-sm-3 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    &-sm-4 {
      padding-top: 1.5rem !important;
      padding-bottom: 1.5rem !important;
    }
    &-sm-5 {
      padding-top: 3rem !important;
      padding-bottom: 3rem !important;
    }
  }
} 

.pt {
  &-0 {
    padding-top: 0 !important;
  }
  &-1 {
    padding-top: 0.25rem !important;
  }
  &-2 {
    padding-top: 0.5rem !important;
  }
  &-3 {
    padding-top: 1rem !important;
  }
  &-4 {
    padding-top: 1.5rem !important;
  }
  &-5 {
    padding-top: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding-top: 0 !important;
    }
    &-xxl-1 {
      padding-top: 0.25rem !important;
    }
    &-xxl-2 {
      padding-top: 0.5rem !important;
    }
    &-xxl-3 {
      padding-top: 1rem !important;
    }
    &-xxl-4 {
      padding-top: 1.5rem !important;
    }
    &-xxl-5 {
      padding-top: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding-top: 0 !important;
    }
    &-xl-1 {
      padding-top: 0.25rem !important;
    }
    &-xl-2 {
      padding-top: 0.5rem !important;
    }
    &-xl-3 {
      padding-top: 1rem !important;
    }
    &-xl-4 {
      padding-top: 1.5rem !important;
    }
    &-xl-5 {
      padding-top: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding-top: 0 !important;
    }
    &-lg-1 {
      padding-top: 0.25rem !important;
    }
    &-lg-2 {
      padding-top: 0.5rem !important;
    }
    &-lg-3 {
      padding-top: 1rem !important;
    }
    &-lg-4 {
      padding-top: 1.5rem !important;
    }
    &-lg-5 {
      padding-top: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding-top: 0 !important;
    }
    &-md-1 {
      padding-top: 0.25rem !important;
    }
    &-md-2 {
      padding-top: 0.5rem !important;
    }
    &-md-3 {
      padding-top: 1rem !important;
    }
    &-md-4 {
      padding-top: 1.5rem !important;
    }
    &-md-5 {
      padding-top: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding-top: 0 !important;
    }
    &-sm-1 {
      padding-top: 0.25rem !important;
    }
    &-sm-2 {
      padding-top: 0.5rem !important;
    }
    &-sm-3 {
      padding-top: 1rem !important;
    }
    &-sm-4 {
      padding-top: 1.5rem !important;
    }
    &-sm-5 {
      padding-top: 3rem !important;
    }
  }
} 

.pe {
  &-0 {
    padding-right: 0 !important;
  }
  &-1 {
    padding-right: 0.25rem !important;
  }
  &-2 {
    padding-right: 0.5rem !important;
  }
  &-3 {
    padding-right: 1rem !important;
  }
  &-4 {
    padding-right: 1.5rem !important;
  }
  &-5 {
    padding-right: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding-right: 0 !important;
    }
    &-xxl-1 {
      padding-right: 0.25rem !important;
    }
    &-xxl-2 {
      padding-right: 0.5rem !important;
    }
    &-xxl-3 {
      padding-right: 1rem !important;
    }
    &-xxl-4 {
      padding-right: 1.5rem !important;
    }
    &-xxl-5 {
      padding-right: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding-right: 0 !important;
    }
    &-xl-1 {
      padding-right: 0.25rem !important;
    }
    &-xl-2 {
      padding-right: 0.5rem !important;
    }
    &-xl-3 {
      padding-right: 1rem !important;
    }
    &-xl-4 {
      padding-right: 1.5rem !important;
    }
    &-xl-5 {
      padding-right: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding-right: 0 !important;
    }
    &-lg-1 {
      padding-right: 0.25rem !important;
    }
    &-lg-2 {
      padding-right: 0.5rem !important;
    }
    &-lg-3 {
      padding-right: 1rem !important;
    }
    &-lg-4 {
      padding-right: 1.5rem !important;
    }
    &-lg-5 {
      padding-right: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding-right: 0 !important;
    }
    &-md-1 {
      padding-right: 0.25rem !important;
    }
    &-md-2 {
      padding-right: 0.5rem !important;
    }
    &-md-3 {
      padding-right: 1rem !important;
    }
    &-md-4 {
      padding-right: 1.5rem !important;
    }
    &-md-5 {
      padding-right: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding-right: 0 !important;
    }
    &-sm-1 {
      padding-right: 0.25rem !important;
    }
    &-sm-2 {
      padding-right: 0.5rem !important;
    }
    &-sm-3 {
      padding-right: 1rem !important;
    }
    &-sm-4 {
      padding-right: 1.5rem !important;
    }
    &-sm-5 {
      padding-right: 3rem !important;
    }
  }
} 


.pb {
  &-0 {
    padding-bottom: 0 !important;
  }
  &-1 {
    padding-bottom: 0.25rem !important;
  }
  &-2 {
    padding-bottom: 0.5rem !important;
  }
  &-3 {
    padding-bottom: 1rem !important;
  }
  &-4 {
    padding-bottom: 1.5rem !important;
  }
  &-5 {
    padding-bottom: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding-bottom: 0 !important;
    }
    &-xxl-1 {
      padding-bottom: 0.25rem !important;
    }
    &-xxl-2 {
      padding-bottom: 0.5rem !important;
    }
    &-xxl-3 {
      padding-bottom: 1rem !important;
    }
    &-xxl-4 {
      padding-bottom: 1.5rem !important;
    }
    &-xxl-5 {
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding-bottom: 0 !important;
    }
    &-xl-1 {
      padding-bottom: 0.25rem !important;
    }
    &-xl-2 {
      padding-bottom: 0.5rem !important;
    }
    &-xl-3 {
      padding-bottom: 1rem !important;
    }
    &-xl-4 {
      padding-bottom: 1.5rem !important;
    }
    &-xl-5 {
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding-bottom: 0 !important;
    }
    &-lg-1 {
      padding-bottom: 0.25rem !important;
    }
    &-lg-2 {
      padding-bottom: 0.5rem !important;
    }
    &-lg-3 {
      padding-bottom: 1rem !important;
    }
    &-lg-4 {
      padding-bottom: 1.5rem !important;
    }
    &-lg-5 {
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding-bottom: 0 !important;
    }
    &-md-1 {
      padding-bottom: 0.25rem !important;
    }
    &-md-2 {
      padding-bottom: 0.5rem !important;
    }
    &-md-3 {
      padding-bottom: 1rem !important;
    }
    &-md-4 {
      padding-bottom: 1.5rem !important;
    }
    &-md-5 {
      padding-bottom: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding-bottom: 0 !important;
    }
    &-sm-1 {
      padding-bottom: 0.25rem !important;
    }
    &-sm-2 {
      padding-bottom: 0.5rem !important;
    }
    &-sm-3 {
      padding-bottom: 1rem !important;
    }
    &-sm-4 {
      padding-bottom: 1.5rem !important;
    }
    &-sm-5 {
      padding-bottom: 3rem !important;
    }
  }
} 

.ps {
  &-0 {
    padding-left: 0 !important;
  }
  &-1 {
    padding-left: 0.25rem !important;
  }
  &-2 {
    padding-left: 0.5rem !important;
  }
  &-3 {
    padding-left: 1rem !important;
  }
  &-4 {
    padding-left: 1.5rem !important;
  }
  &-5 {
    padding-left: 3rem !important;
  }
  @media (min-width:1400px) {
    &-xxl-0 {
      padding-left: 0 !important;
    }
    &-xxl-1 {
      padding-left: 0.25rem !important;
    }
    &-xxl-2 {
      padding-left: 0.5rem !important;
    }
    &-xxl-3 {
      padding-left: 1rem !important;
    }
    &-xxl-4 {
      padding-left: 1.5rem !important;
    }
    &-xxl-5 {
      padding-left: 3rem !important;
    }
  }
  @media (min-width:1200px) {
    &-xl-0 {
      padding-left: 0 !important;
    }
    &-xl-1 {
      padding-left: 0.25rem !important;
    }
    &-xl-2 {
      padding-left: 0.5rem !important;
    }
    &-xl-3 {
      padding-left: 1rem !important;
    }
    &-xl-4 {
      padding-left: 1.5rem !important;
    }
    &-xl-5 {
      padding-left: 3rem !important;
    }
  }
  @media (min-width:992px) {
    &-lg-0 {
      padding-left: 0 !important;
    }
    &-lg-1 {
      padding-left: 0.25rem !important;
    }
    &-lg-2 {
      padding-left: 0.5rem !important;
    }
    &-lg-3 {
      padding-left: 1rem !important;
    }
    &-lg-4 {
      padding-left: 1.5rem !important;
    }
    &-lg-5 {
      padding-left: 3rem !important;
    }
  }
  @media (min-width:768px) {
    &-md-0 {
      padding-left: 0 !important;
    }
    &-md-1 {
      padding-left: 0.25rem !important;
    }
    &-md-2 {
      padding-left: 0.5rem !important;
    }
    &-md-3 {
      padding-left: 1rem !important;
    }
    &-md-4 {
      padding-left: 1.5rem !important;
    }
    &-md-5 {
      padding-left: 3rem !important;
    }
  }
  @media (min-width:576px) {
    &-sm-0 {
      padding-left: 0 !important;
    }
    &-sm-1 {
      padding-left: 0.25rem !important;
    }
    &-sm-2 {
      padding-left: 0.5rem !important;
    }
    &-sm-3 {
      padding-left: 1rem !important;
    }
    &-sm-4 {
      padding-left: 1.5rem !important;
    }
    &-sm-5 {
      padding-left: 3rem !important;
    }
  }
} 


.text {
  &-danger {
    color: #B02E46 !important;
  }
  &-primary {
      color: #9057FD !important;
  }
  &-secondary {
    color: #6c757d !important;
  }
  &-success {
    color: #40B869 !important;
  }
  &-warning {
    color: #FF9601 !important;
  }
  &-blue {
    color: #092966 !important;
  }
  &-info {
    color: #0dcaf0 !important;
  }
  &-light {
    color: #f8f9fa !important;
  }
  &-dark {
    color: #212529 !important;
  }
  &-body {
    color: #212529 !important;
  }
  &-muted {
    color: #6c757d !important;
  }
  &-white {
    color: #fff !important;
  }
  &-black-50 {
    color: rgba(0, 0, 0, .5) !important;
  }
  &-white-50 {
    color: rgba(255, 255, 255, .5) !important;
  }
} 
.bg {
  &-lightgrey {
    background-color: #F2F2F2 !important;
  }
  &-danger {
    background-color: #B02E46 !important;
  }
  &-primary {
      background-color: #9057FD !important;
  }
  &-secondary {
    background-color: #6c757d !important;
  }
  &-success {
    background-color: #9057FD !important;
  }
  &-warning {
    background-color: #FF9601 !important;
  }
  &-blue {
    background-color: #092966 !important;
  }
  &-info {
    background-color: #0dcaf0 !important;
  }
  &-light {
    background-color: #f8f9fa !important;
  }
  &-dark {
    background-color: #212529 !important;
  }
  &-body {
    background-color: #212529 !important;
  }
  &-muted {
    background-color: #6c757d !important;
  }
  &-white {
    background-color: #fff !important;
  }
  &-black-50 {
    background-color: rgba(0, 0, 0, .5) !important;
  }
  &-white-50 {
    background-color: rgba(255, 255, 255, .5) !important;
  }
} 

.fw {
  &-100 {
    font-weight: 100 !important;
  }
  &-200 {
    font-weight: 200 !important;
  }
  &-300 {
    font-weight: 300 !important;
  }
  &-400 {
    font-weight: 400 !important;
  }
  &-500 {
    font-weight: 500 !important;
  }
  &-600 {
    font-weight: 600 !important;
  }
  &-700 {
    font-weight: 700 !important;
  }
  &-800 {
    font-weight: 800 !important;
  }
  &-900 {
    font-weight: 900 !important;
  }
}

.w {
  &-25 {
    width: 25% !important;
  }
  &-50 {
    width: 50% !important;
  }
  &-75 {
    width: 75% !important;
  }
  &-100 {
    width: 100% !important;
  }
}

.align-self-center {
  align-self: center !important;
}