@import "./variables.scss";
@import "./icomoon/style.css";
@import "./tabs.scss";
:root {
  --black: $black;
  --bs-primary-rgb: 155, 8, 8;
  --bs-link-hover-color: #0873BB;
  --bs-link-color: #0873BB;
}

* {
  box-sizing: border-box !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 10px;
}

::-webkit-scrollbar-track {
  background: $white;
}

::-webkit-scrollbar-thumb {
  background: $color_primary;
  border-radius: 0;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dropdown-menu {
  --bs-dropdown-link-active-bg: #a60a0a;
}
ul {
  padding: 0;
  list-style: none;
  margin: 0;
}
hr {
  border-color: #e1e1e142;
  margin: 10px 0;
}
.r_icon {
  margin-left: 6px;
  vertical-align: middle;
}
.cursor-pointer {
  cursor: pointer;
}
.nowrap {
  white-space: nowrap;
}
.rounded {
  border-radius: 16px;
}
.position-relative {
  position: relative;
}
.nav-pills {
  --bs-nav-pills-link-active-bg: #0873BB;
}
.nav-link {
  &:focus-visible {
    box-shadow: none;
  }
} 
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: $black;
  transition: background-color 5000s ease-in-out 0s;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}
body {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: $black;
  background-color: #F7F9FC;
  font-family: $theme_font;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  color: #000;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 .5rem;
}
h1,h2,h3,h4,h5,h6,a,ul,span,input,select,button,textarea {
  font-family: $theme_font;
}
h6 {
  @media (max-width: 1399px) and (min-width: 992px) {
    font-size: 14px;
  }

  @media (max-width: 767px) {
    font-size: 14px;
  }
}

p {
  font-size: 16px;
  color: var(--black);
}

a {
  color: $black;
  text-decoration: none;

  &:visited {
    color: $black;
  }
}

// button,input,textarea,select,a,p,span,i {
  
// }

.w-100 {
  width: 100% !important;
}
.mw-260 {
  max-width: 260px;
  width: 100%;
}
.border-0 {
  border: none !important;
}
.border-1 {
  border: 1px solid #D9D9D9 !important;
}
.heading {
  font-size: 32px;
  margin-bottom: 2px;
  @media (max-width: 991px) {
    font-size: 26px;
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    font-size: 22px;
  }
}
.mheading {
  font-size: 32px;
  margin-bottom: 2px;
  font-weight: 500;
  color: #344054;
  @media (max-width: 767px) {
    font-size: 24px;
  }
}
.title {
  font-size: 22px;
}
.para {
  color: $grey;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
  @media (max-width: 991px) {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .head-name {
    display: none;
  }
}
.headflex {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 30px;
  @media (max-width: 991px) {
    gap: 16px;
  }
}
.centerflex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  @media (max-width: 991px) {
    gap: 16px;
  }
}
.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-wrap: wrap;
    gap: 10px;
  }
}

.text {
  &-center {
    text-align: center !important;
  }

  &-right {
    text-align: right !important;
  }
  &-left {
    text-align: left !important;
  }
  &-primary {
    color: $color_primary;
  }
  &-grey {
    color: $grey;
  }
}

@media (max-width: 767px) {
  .main_header {
    .dropdown {      
      .title {
        display: none;
      }
    }
  }
}
@media (min-width: 992px) {
  .main_header {
    .form-group {
        max-width: 630px;
        width: 100%;
    }
  }
}

// Sidebar Collapse
.sidebar_collapse {
  .dashboard_layout {
    .main_content {
      @media (min-width : 992px) {
        padding-left: 90px;
      }
      @media (max-width : 991px) {
        &:after {
          content: '';
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          background: #52525240;
          z-index: 1111;
        }
      }
    }
  }
  @media (max-width : 1199px) {
    .dashboard_layout {
      .main_content {
        @media (min-width : 768px) {
          padding-left: 0;
        }
      }
    }
  }
}


// Form Input and Select Style

input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {
  height: 100%;
  width: 20px;
  position: absolute;
  right: 15px;
  top: 10px;
  background-size: 20px;
  cursor: pointer;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  left: 8px;
}
input[type="date"]::-webkit-calendar-picker-indicator { 
  // background-image: url('../images/icons/calender.svg');
}
input[type="time"]::-webkit-calendar-picker-indicator {
  // background-image: url('../images/icons/time.svg');
}

input[type="date"],input[type="time"] {
  padding-right: 50px;
  position: relative;
}
select {
  &.form-control {
    background-image: url("../images/icons/arrow-down.svg");
    background-repeat: no-repeat;
    background-position: right 0.95rem center;
    background-size: 14px;
    appearance: none;
  }
}
label {
  margin-bottom: 4px;
  display: block;
  color: #262626;
  font-weight: 600;
}

.form-group {
  display: block;
  margin: 0;
  margin-bottom: 20px;
  position: relative;

  .icon-field {
    position: relative;

    .icon {
      position: absolute;
      font-size: 20px;
      top: 50%;
      transform: translateY(-50%);
      left: 15px;
      transition: all 0.3s;
      height: 20px;
      width: 20px;
      object-fit: contain;
      &.password-icon {
        font-size: 22px;
        height: 22px;
      }
    }

    .form-control,.form-control-shape,.form-select-shape {
      padding-left: 42px;
    }
    .danger-color {
      color: #b42318 !important;
      margin-top: 2px;
      float: left;
    }
    
    .is-invalid_boder {
      border: 1px solid #b42318 !important;
      background-image: none;
    }
    
  }
}

.form-control {
  @include form-control;
}
.form-control-shape {
  @include form-control-shape;
}
textarea.form-control {
  border-radius: 10px;
}

.form-check {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  &.checkbox {
    .form-check-input {
      height: 0;
      width: 0;
      opacity: 0;

      &:checked {
        ~.form-check-checkmark {

          // border: 0;
          &::after {
            transform: scale(1);
          }
        }
      }
    }

    .form-check-checkmark {
      display: inline-block;
      height: 22px;
      width: 22px;
      min-width: 22px;
      background-color: $white;
      position: relative;
      overflow: hidden;
      box-shadow: inset 0px 15px 15px #EEEEF6;
      border: 1px solid #DCDFE2;
      border-radius: 5px;

      &::after {
        content: "\e935";
        font-family: "icomoon";
        position: absolute;
        left: 0;
        top: 0px;
        height: 100%;
        width: 100%;
        font-size: 18px;
        border-radius: 100%;
        transition: all 0.8s cubic-bezier(0.19, 1, 0.22, 1);
        transform: scale(0);
      }
    }
  }

  label {
    margin-left: 10px;
    color: $black;
    font-weight: 600;
    font-size: 16px;
  }
  &.form-switch {
    padding-left: 0;
    padding-right: 60px;
    .form-check-input {
      position: absolute;
      right: 0;
      /* background-color: #333333; */
      width: 50px;
      height: 20px;
      &:checked {
        background-color: #333333;
        border-color: #333333;
      }
      &:focus {
        box-shadow: none;
      }
    }
    .form-check-label {
      font-size: 20px;
      margin-left: 0;
    }
  }
}

.link {
  color: #000000;
  font-size: 16px;
  background: transparent;
  border: none;
  padding: 0;
  font-weight: 700;
  cursor: pointer;
  i {
    font-size: 14px;
    margin-left: 2px;
  }
  &:visited {
    color: #000;
  }
  &:hover {
    color: #9057FD;
  }
}



.proimg {
  display: flex;
  align-items: center;
  gap: 12px;
  white-space: nowrap;
  color: #000;
  font-weight: 500;
  font-size: 16px;
  img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }
  a {
    font-size: 18px;
    color: #000;
    font-weight: 500;
  }
}

// Btn Style

.btn {
  border: 0;
  outline: 0;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 30px;
  text-align: center;
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid $white;
  display: inline-block;
  &:visited {
    color: $white;
  }
  .icon {
      vertical-align: middle;
      width: 20px;
      font-size: 16px;
      position: relative;
      top: -2px;
  }
  &-primary {
    background: $color_primary;
    color: $white;
    border-color: $color_primary;
    &:hover {
      background: #854bf0;
    }
    
  }
 
  &-link {
    background: transparent;
    padding: 0;
    border: none;
    color: #717171;
    font-weight: 500;
  }

 
  &-sm {
    font-size: 14px;
    padding: 8px 20px;
  }

  @media (max-width: 767px) {
    font-size: 12px;
    padding: 8px 22px;
  }
}

.btngroup {
  display: flex;
  gap: 15px;
  .btn {
    width: 50%;
  }
}



//Card Style
.card {
  border-radius: 0;
  border: 1px solid #E7E7E7;
  background: #FFF;
  &-sm {
    .card-body {
      padding: 16px;
    }
  }
  &-lg {
    .card-body {
      padding: 40px;
      margin-top: 20px;
      @media (max-width: 1199px) {
        padding: 20px;
      }
    }
  }
  &-body {
    padding: 18px 22px;
    @media (max-width: 767px) {
      padding: 16px;
    }
  }
  &-header {
      padding: 14px 20px;
      border-bottom: 1px solid #232C33;
      .title {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 0;
      }
  }
}


//Dropdown Style
.dropdown {
  &.right {
    .dropdownContent {
      left: auto;
      right: 0;
    }
  }
}
.filter-dropdown {
  .form-group {
    width: 250px;
  }
} 


// Tabs Style
.nav-pills {
  .list-group {
    .nav-item {
      .show> {
        .nav-link {
          .list-group-item {
            &:hover {
              background: $color_primary;

              a {
                color: $white;

                .text-primary {
                  color: $white !important;
                }
              }
            }
          }
        }
      }

      .nav-link {
        padding: 0;
        background: transparent;
        border-radius: 0;
        border: none;

        &:focus-visible {
          box-shadow: none;
        }

        .list-group-item {
          &:focus-visible {
            outline: none;
          }
        }

        &.active {
          .list-group-item {
            background: $color_primary;
            color: $white;
            .text-primary {
              color: $white !important;
            }
           
          }
        }
      }
    }
  }
  .nav-link {
    &.radionav {
        padding: 0;
        background: transparent;
        color: #000;
        border: none;
        padding-left: 20px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            height: 16px;
            width: 16px;
            border: 1px solid #000;
            border-radius: 50%;
            top: 4px;
        }
        &:after {
            content: '';
            position: absolute;
            left: 3px;
            width: 10px;
            height: 10px;
            background: $color_primary;
            border-radius: 50%;
            top: 7px;
            opacity: 0;
            transition: all .3s;
        }
        &.active {
          &:after {
              opacity: 1;
          }
        }
    }
  } 
}
.nav-pills {
  .nav-link {
    border-radius: 50px;
    border: 0;
    outline: 0;
    border-radius: 50px;
    font-size: 16px;
    font-weight: 600;
    padding: 11px 30px;
    border: 1px solid $color_primary;
    @media (max-width: 767px) {
      font-size: 14px;
      font-weight: 600;
      padding: 8px 20px;
    }
  }
} 

// Modal Style
.modal-header {
  border: none;
  padding: 0;
  button {
   position: absolute;
   right: 20px;
   top: 20px;
   z-index: 1;
   --bs-btn-close-focus-shadow: none;
  }
}

.custom-checkbox {
  position: relative;
  input {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    z-index: 1;
    cursor: pointer;
  }
  .label-checkbox {
    width: 22px;
    height: 22px;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #00000078;
    }
    &:after {
      content: "\e935";
      font-family: 'icomoon';
      color: $white;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid $color_primary;
      background: $color_primary;
      opacity: 0;
    }
  }
  input:checked+.label-checkbox {
    &:after {
      opacity: 1;
    }
  }
}


// Modal 
.modal-inner {
  .title {
    color: $black;
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      font-size: 24px;
    }
  }
  .para {
    font-size: 22px;
    @media (max-width: 767px) {
      font-size: 18px;
      br {
        display: none;
      }
    }
  }
  .icon_img {
    width: 140px;
    height: 140px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      width: 120px;
      height: 120px;
      margin-bottom: 10px;
    }
  }
}




.sm-modal {
  .modal {
    max-width: 560px;
  }
}

.sidetab {
  display: flex;
  gap: 14px;
  @media (max-width: 991px) {
    display: block;
  }
  .maintab {
    @media (max-width: 991px) {
      &.listhide {
        &.hide {
          z-index: unset;
        }
      } 
      position: relative;
      z-index: 11;
    }
    .react-tabs__tab-list {
      @media (max-width: 991px) {
        background: $white;
        display: flex;
        flex-wrap: nowrap;
        padding: 12px;
        margin-bottom: 0;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  } 
  .react-tabs__tab-panel {
    width: 100%;
    .content {
      height: 100%;
      @media (max-width: 991px) {
        position: fixed;
        top: 60px;
        background: #fff;
        left: 0;
        right: 0;
        bottom: 0;
        height: auto;
        opacity: 0;
        overflow: auto;
        visibility: hidden;
        &::-webkit-scrollbar {
          width: 0;
        }
        @media (max-width: 767px) {
          top: 50px;
        }
      }
     
      .react-tabs__tab-panel {
        height: 93%;
        @media (max-width: 1199px) {
          height: auto;
        }
        .card {
          height: 100%;
          width: 60%;
          border-radius: 10px;
          .channel-icons {
            display: flex;
            flex-wrap: wrap;
          }
          
          .channel-icon {
            position: absolute;
            margin: 14px 22px;
            display: flex;
          }
          
          .channel-icon img {
            margin-right: 8px;
          }
        }
      }
      // .sidetab {
      //   height: 100%;
      // }
    }
  }
  .react-tabs__tab-panel--selected {
    .content {
      opacity: 1;
      visibility: unset;
      z-index: 1;
    }
  }
  .react-tabs__tab-list {
    display: inline-block;
    .react-tabs__tab {
      display: block;
      padding: 10px 15px;
      border-radius: 4px;
      margin-bottom: 6px;
      background: transparent;
      color: #344054;
      font-size: 16px;
      font-weight: 400;
      white-space: nowrap;
      @media (max-width: 991px) {
        padding: 8px 10px;
        font-size: 14px;
      }
      i,img {
        font-size: 20px;
        width: 20px;
        margin-right: 10px;
        vertical-align: middle;
        position: relative;
        // top: -2px;
      }
    }
    .react-tabs__tab--selected {
      background: #F1EAFF;
      color: #000;
    }
  }
}

.backbtn {
  padding: 10px;
  font-size: 20px;
  padding-bottom: 8px;
  @media (min-width: 992px ){
    display: none;
  }
}
.artistlist .no-artists-message {
  font-size: 1.2em;
  color: #888; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh; 
  text-align: center;
}
.artistlist {

  .sidetab {
    .react-tabs__tab-list {
      z-index: 11;
      position: relative;
        min-width: 240px;
        background: #fff;
        padding: 20px;
        border: 1px solid #E4E7EC;
        max-height: 900px;
        overflow: auto;
        margin-bottom: 0;
        @media (max-width: 1399px) {
          max-height: 700px;
        }
        @media (max-width: 991px) {
          min-width: auto;
          width: 100%;
          max-height: fit-content;
        }
        &::-webkit-scrollbar {
          width: 0;
        }
        .react-tabs__tab {
            background: transparent;
            border-bottom: 1px solid #E4E7EC;
            border-radius: 0;
            padding: 12px 0;
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 18px;
            font-weight: 400;
            @media (max-width: 991px) {
              padding: 8px 0;
              font-size: 16px;
            }
            &:first-child {
                padding-top: 0;
            }
            &:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
            img {
                margin: 0;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
            }
        }
    }
    .listhide {
      &.hide {
        .react-tabs__tab-list {
          z-index: unset;
        }
      }
    }
  } 
  .content {
    .sidetab {
      display: block;
    }
    .toolbar {
        margin-top: -40px;
        position: relative;
        top: -80px;
        @media (max-width: 1199px) {
          margin-top: 20px;
          position: relative;
          top: 0;
        }
    }
  }
} 
.artist-card {
  @media (max-width: 991px) {
    border: none;
  }
}
#chart-timeline {
  margin: 0 -20px;
}

.custom-tab {
  display: flex;
  overflow-x: auto; /* Allows horizontal scrolling */
  overflow-y: hidden; 
  -ms-overflow-style: none;
  scrollbar-width: none; 
}

.custom-tab::-webkit-scrollbar {
  display: none;
}

.custom-tab > * {
  flex-shrink: 0; 
  padding: 0 10px;
}
.tabplat {
  // display: inline-flex;
  //   width: 100%;
  //   overflow: auto;

  //   -ms-overflow-style: none;
  // scrollbar-width: none;
  
  .react-tabs__tab {
    background: transparent;
    color: #344054;
    display: inline-flex;
    align-items: center;
    font-size: 14px;
    padding: 16px 14px;
    border-bottom: 1px solid #E4E7EC;
    border-radius: 0;
    @media (max-width: 991px) {
      font-size: 0;
      padding: 6px;
      border: none;
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 20px;
        right: 20px;
        height: 2px;
        border-radius: 10px;
        background: $color_primary;
        opacity: 0;
      }
    }
    img {
      width: 46px;
      height: 46px;
      object-fit: contain;
      @media (min-width: 992px) {
        display: none;
      }
    }
  }
  .react-tabs__tab--selected {
    color: $color_primary;border-color: $color_primary;
    &:after {
      opacity: 1;
    }
  }
  @media (max-width: 991px) {
    display: flex;
    white-space: nowrap;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      display: none;
    }
  }
} 

.toolbar {
  text-align: right;
  margin-bottom: 40px;
  display: flex;
  justify-content: end;
  align-items: center;
  button {
    background: #E0E5E9;
    border: none;
    color: $color_secondary;
    padding: 4px 8px;
    border-radius: 2px;
    margin: 0 4px;
    font-size: 10px;
    cursor: pointer;
    &.active {
      background: $color_secondary;
      color: $white;
    }
  }
  p {
    color: $grey;
    font-size: 12px;
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.artistdata {
  
  .proimg {
    margin-bottom: 20px;
    img {
      height: 110px;
      width: 110px;
      object-fit: cover;
      @media (max-width: 991px) {
        height: 80px;
        width: 80px;
      }
      @media (max-width: 767px) {
        height: 60px;
        width: 60px;
      }
    }
    .name {
      font-size: 32px;
      color: #3C3C3C;
      @media (max-width: 991px) {
        font-size: 20px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 991px) {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;
    .proimg {
      margin-bottom: 0;
    }
  }
} 

.listdata {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 50px;

  @media (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    margin-top: 30px;
  }

  .cards {
    background-color: #f9f9f9;
    padding: 16px;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    position: relative;
    height: 100%;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    color: #3C3C3C;
    margin-bottom: 8px; /* Space between title and status */
  }

  .status {
    font-weight: 300;
    color: #9E9E9E;
    font-size: 14px;
    margin-bottom: 0;
    text-transform: capitalize;
  }

  .total {
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 0;
    position: absolute;
    top: 16px;
    right: 16px;
    
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}





.investlist {
  @media (min-width: 992px ){
    margin-top: 20px;
  }
  .form-group {
    width: 100%;
  }
  .d-flex {
      gap: 20px;
      align-items: center;
      margin-bottom: 20px;
  }
  button {
      background: transparent;
      padding: 0;
      border: none;
      font-size: 20px;
      cursor: pointer;
  }
  ul {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
    }
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #E4E7EC;
      padding: 12px 0;
      .proimg {
        img {
          height: 45px;
          width: 45px;
          object-fit: cover;
        }
        .name {
          font-size: 16px;
          font-weight: 400;
          margin-bottom: 2px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          margin-bottom: 0;
          color: #929292;
        }
      } 
      .rate {
        text-align: right;
        h2 {
          font-size: 16px;
          color: #3C3C3C;
          font-weight: 500;
          margin-bottom: 2px;
        }
        p {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 12px;
          color: #565656;
        }
        .error {
          color: #FF404B;
        }
        .success {
          color: #40B869;
        }
      }
    }
  }
} 

.btcwrap {
  @media (max-width:991px) {
    padding: 16px;
    background: #fff;
    .content {
      position: fixed;
      top: 60px;
      background: #fff;
      left: 0;
      right: 0;
      bottom: 0;
      height: auto;
      opacity: 0;
      overflow: auto;
      visibility: hidden;
      @media (max-width:767px) {
        top: 50px;
      }
    }
  }
}
.showdata {
  .btcwrap {
    .content {
      visibility: visible;
      opacity: 1;
      z-index: 1;
    }
  } 
} 