@import "../../variables.scss";

.social_login_wrapper {
  .or_divider {
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: $black;
    font-weight: 600;
    justify-content: center;
    text-align: center;
    margin: 10px 0 20px;
   
    // &::before {
    //   content: '';
    //   background: linear-gradient(90deg, #FFFFFF, #FFFFFF00);
    //   height: 2px;
    //   width: 42%;
    //   margin-right: 30px;
    // }
    // &::after {
    //   content: '';
    //   background: linear-gradient(270deg, #FFFFFF, #FFFFFF00);
    //   height: 2px;
    //   width: 42%;
    //   margin-left: 30px;
    // }
  }
  .buttons_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    .login_button {
      border-radius: 6px;
      background: #d6d6d6;
      border: 0;
      outline: 0;
      font-size: 14px;
      cursor: pointer;
      color: $dark;
      width: 150px;
      padding: 12px 10px;
      font-weight: 600;
      img {
        vertical-align: middle;
        margin-right: 4px;
        width: 20px;
        position: relative;
        top: -1px;
      }
    }
  }
}
